var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "swpOnSiteMeasureTable",
          attrs: {
            title: "현장조치사항 목록",
            columns: _vm.grid.columns,
            gridHeightAuto: "",
            data: _vm.workPermit.onSiteMeasures,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable,
            selection: "multiple",
            rowKey: "sopOnSiteMeasureId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "currentSituation"
                    ? [
                        _c("c-upload-picture", {
                          staticClass: "swpOnSiteMeasurePicture",
                          attrs: {
                            height: "150px",
                            editable: _vm.editable,
                            attachInfo: {
                              isSubmit: "",
                              taskClassCd: "WORK_PERMIT_ON_SITE_CURRENT",
                              taskKey: props.row.sopOnSiteMeasureId,
                            },
                          },
                        }),
                        _c("c-textarea-column", {
                          attrs: {
                            editable: _vm.editable,
                            col: col,
                            props: props,
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props, col)
                            },
                          },
                        }),
                      ]
                    : col.name === "actionSituation"
                    ? [
                        _c("c-upload-picture", {
                          staticClass: "swpOnSiteMeasurePicture",
                          attrs: {
                            height: "150px",
                            editable: _vm.editable,
                            attachInfo: {
                              isSubmit: "",
                              taskClassCd: "WORK_PERMIT_ON_SITE_ACTION",
                              taskKey: props.row.sopOnSiteMeasureId,
                            },
                          },
                        }),
                        _c("c-textarea-column", {
                          attrs: {
                            editable: _vm.editable,
                            col: col,
                            props: props,
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props, col)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addInfo },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          label: "제외",
                          icon: "remove",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }